<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultIndexHeader :routePush="'/RegisterService'" :titleHeader="'Registrar nuevo servicio'" />
            <!-- Table -->
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Listado de servicios</h5>
                            </div>

                            <div class="header-left-services" style="display: flex;">
                                <div class="new-lic-content2">
                                    <div class="btn-new-tender"
                                        @click="typeServiceFilter = 'acueducto', getServices('acueducto')">
                                        Acueducto
                                    </div>
                                </div>

                                <div class="new-lic-content2">
                                    <div class="btn-new-tender"
                                        @click="typeServiceFilter = 'alcantarillado', getServices('alcantarillado')">
                                        Alcantarillado
                                    </div>
                                </div>

                                <div class="new-lic-content2">
                                    <div class="btn-new-tender" @click="typeServiceFilter = 'aseo', getServices('aseo')">
                                        Aseo
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Nombre</th>
                                    <th class="th-colum-item">Tipo documento</th>
                                    <th class="th-colum-item">Fecha</th>
                                </tr>
                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in listServices" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.name }}</td>
                                        <td class="th-colum-item">Alguna cosa</td>
                                        <td class="th-colum-item"> {{ item.date }} </td>

                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <td class="td-container-action td-success" @click="openPdf(item.urlPdf)">
                                            <i class="el-icon-success icon-action"></i>
                                        </td>

                                        <router-link class="text-white"
                                            :to="{ name: 'editService', params: { _id: item._idName, _type_service: typeServiceFilter } }">
                                            <td class="td-container-action td-warning">
                                                <i class="el-icon-edit icon-action"></i>
                                            </td>
                                        </router-link>

                                        <td class="td-container-action td-danger"
                                            @click="showConfirmation(index, item._idName)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>

                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </a-col>
            </a-row>
            <!-- End Table -->
        </div>
    </div>
</template>

<script>

import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader
    },
    data() {
        return {
            listServices: [],
            isLoading: false,
            typeServiceFilter: 'acueducto'
        }
    },
    mounted() {
        this.getServices(this.typeServiceFilter)
    },
    methods: {
        async getServices(typeServiceFilter) {
            this.isLoading = true
            await db.collection('services')
                .doc(typeServiceFilter)
                .get()
                .then((querySnapshot) => {
                    this.listServices = querySnapshot.data().services;
                    this.isLoading = false
                }).finally(() => {
                    this.isLoading = false
                })

        },

        showConfirmation(doc_id, name) {
            this.$swal
                .fire({
                    title: 'Eliminar servicio',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteStorageFolder(doc_id, name);
                });
        },

        async deleteStorageFolder(childName, name) {
            this.isLoading = true
            const storageRef = storage.ref()
            const pdfRef = storageRef.child('/pdfsServices')
                .child(this.typeServiceFilter)
                .child(name)

            try {
                await this.removeService(name)
                await pdfRef.delete()
                this.$swal
                    .fire({
                        title: 'Acción exitosa',
                        text: 'Licitacion eliminada exitosamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                await this.getServices(this.typeServiceFilter)
            } catch (error) {                
                this.isLoading = false
            }
        },

        async removeService(name) {
            const docRef = db.collection('services').doc(this.typeServiceFilter);
            const doc = await docRef.get();
            const array = doc.get('services');
            const index = array.findIndex((item) => item._idName === name);
            (array);

            if (index > -1) {
                array.splice(index, 1);
            }
            await docRef.update({ ['services']: array });
        },

        openPdf(urlPdf) { window.open(urlPdf, '_blank'); }
    },
}
</script>

<style>
.new-lic-content2 {
    cursor: pointer;
    float: right;
    display: flex;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--colorGreenPrimary);
}

.btn-new-tender {
    padding: 10px;
    border: 0;
    color: white;
    background-color: transparent;
}
</style>